.hero{
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    position: relative;
}
.hero article{
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}
.hero h1{
    text-align: center;
    font-size: 50px;
}
.hero p{
    text-align: center;
    width: 77%;
    font-size: 25px;
}
.hero-text-span{
    color: #175ADC;


}

.text-span{
    color: #175adc;
    font-weight: 600;
}

.billion{
    font-weight: 600;
}
.hero main{
    display: flex;
    gap: 20px;
    align-items: center;
    z-index: 2;
}
.hero main .dollar span{
    display: flex;
    gap: 20px;
    margin-top: 1rem;
    position: relative;
}
.hero #hero_bg{
    position: absolute;
    z-index: 1;
    bottom: 5%;
}

@media (max-width:620px) {
    .hero h1{
        font-size: 30px;
    }
    .hero p{
        font-size: 16px;
        width: 100%;
    }
    .hero main{
        flex-direction: column;
    }
    .hero #hero_bg{
        bottom: 50%;
    }
    .hero #cryptoC{
        display: none;
    }
}