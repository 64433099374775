.App{
 
  margin: auto;
  overflow-x: hidden;
  padding: 0px;
 
}
.no-scroll{
  overflow: hidden;
}
