.checkoutPage{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  padding-top:0px ;
 
 
 
    
 
}

@media screen and (max-width:768px) {
  .checkoutPage{
    
    padding: 0px;
    
  }
  }
  
.checkoutPage1{
    border: 1px solid rgb(185, 185, 185); 
    width: 30%;
    margin: auto;
    margin-top: 3rem;
    margin-bottom: 3rem;
    border-radius: 5px; 
    padding: 1rem;
    position: relative;
    opacity: .6;
}