footer{
    background-color: #132944;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 7rem;
    padding-bottom: 4rem;
    color: white;
}
footer div{
    display: flex;
}
footer div:nth-child(1){
    justify-content: space-between;
    padding: 0 24px;
}
footer div:nth-child(1) span{
    display: flex;
    align-items: center;
    gap: 10px;
}
footer div:nth-child(1) span img:nth-child(1){
    background-color: white;
    border-radius: 50%;
}
footer .bottom{
    justify-content: space-between;
    padding: 0 24px;
}
footer div ul{
    display: flex;
    list-style: none;
    align-items: center;
    gap: 2rem;
}
footer .bottom ul{
    gap: 3rem;
    font-size: 14px;
}
footer hr{
    margin-block: 1rem;
}

@media (max-width:620px){
    footer{
        margin-inline: -1rem;
        padding: 0;
        padding-block: 60px;
    }
    footer div:nth-child(1){
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }
    footer div:nth-child(1) ul{
        gap: 1rem;
    }
    footer div:nth-child(1) ul img{
        width: 30px;
    }
    footer .bottom{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }
    footer .bottom ul{
        flex-direction: column;
        gap: 1rem;
    }
}