.service {
  margin-top: 3rem;
}
.service-h1 {
  align-items: center;
  font-size: 48px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 6rem;
  line-height: 63px;
  text-align: center;
}
.service-p {
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
  width: 80%;
  padding-top: 20px;
  line-height: 35px;
  font-weight: 400;
  height: 210px;
  font-size: 25px;
}

.lever {
  color: #175adc;
  font-weight: 700;
}

.shape-1 {
  position: absolute;
  /* margin-top: 5px; */
  bottom: -100px;

  left: -95px;
}

.shape{
    position: relative;
}

.started{
    align-items: center;
    margin-top: 70px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    line-height: 53px;
    text-align: center;
    position: relative;
}

.started-h1{
    font-size: 45px;
    font-weight: 700;
}

.visit{
   color: #175ADC; 
   font-weight:600 ;
   letter-spacing: 5.5%;
   width: 20px;
   margin-bottom: 13rem;
}
.shapex{
    position: absolute;
    top: 0;
    right: -3rem;
}

@media (max-width:620px){
  .service-h1{
    font-size: 25px;
    line-height: 40px;
  }
  .service-p{
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10rem;
  }
  .shaper{
    width: 100px;
  }
  .why2{
    display: none;
  }
  .started{
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .started-h1{
    font-size: 25px;
  }
}