.investmentPageForm {
  width: 100%;
  margin: auto;
  /* margin-top: 2rem; */
  /* margin-bottom: 4rem; */
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  /* border: 1px solid; */
}
.investmentPageForm .i-logo {
  margin: 20px 40px;
  margin-left: auto;
  width: 200px;
}

.investmentPageForm .secure {
  width: 200px;
  padding: 20px;
  margin-left: auto;
  margin-top: 3rem;
}
.investment {
  display: flex;
  justify-content: space-between;
  padding: 32px 32px;
}
.invest-header{
  display: flex;
  flex-direction: column;
}
.investment h1 {
  font-family: 'Agrandir-Bold';
  font-size: 32px;
  font-weight: 700;
  line-height: 37px;
  letter-spacing: -0.019em;
  color: #082e88;
}
.investment p {
  font-family: 'Agrandir-Regular';
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.006em;
  margin-bottom: 2rem;
  padding: 0 !important;
}
.investment form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 60%;
  /* border: 1px solid; */
}
.investment form label {
  display: flex;
  justify-content: space-between;
  border: none;
  position: relative;
  font-family: 'Agrandir-Regular';
}
.investment form label input {
  width: 70%;
  outline: none;
  padding: 5px 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid;
  font-family: 'Agrandir-Regular';

}
.i-visible-blind{
  position: absolute;
  right: 2%;
  opacity: .7;
  top: 2%;
}
.investment form label select {
  width: 70%;
  border: 1px solid;
  outline: none;
  border-radius: 5px;
  padding: 5px 10px;

}
.sel-lab{
  display: flex;
  align-items: center;
}
#dob {
  display: flex;
  /* margin-bottom: -15px; */
  /* padding-left: 10px; */
  align-items: center;
}
#dob p {
  width: 30%;
}
#dob span {
  /* border: 1px solid ; */
  border-right: 1px solid;
  height: fit-content;
  border-radius: 5px;
  width: 70%;
  font-family: "Times New Roman", Times, serif;
  overflow-x: hidden !important;
}
.datePicker{
    border: none !important;
    width: 60vw;
    border: 1px solid;
    outline: none;
    padding-block: -10px;
    margin: auto;
}
.ndpr{
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  margin-left: 30%;
  font-family: 'Agrandir-Regular';
}
.ndpr input{
  margin: 5px;
}
.ndpr small{
  color: black !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.investment form small {
  width: fit-content;
  margin-left: auto;
  margin-right: 0rem;
  font-family: Agrandir;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.006em;
  color: #ff2424;
}
.investment form label input::placeholder {
  font-size: 12px;
  color: #0e8bff;
  font-family: Agrandir;
}
.investment .btns {
  display: flex;
  gap: 10px;
  width: 70%;
  font-size: 14px;
  padding: 10px;
  margin-top: 3rem;
  margin-left: auto;
}
.investment .btns button{
  padding: 10px 30px;
  border-radius: 5px;
  width: 300px;
}
.investment .btns button:nth-child(1) {
  background-color: #0051ff;
  border: none;
  color: white;
}
.investment .btns button:nth-child(2) {
  background-color: #f21010;
  border: none;
  color: white;
}
.investment section {
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.investment section img {
  width: 300px;
  margin: auto;
}
.loading{
  width: 30px;
  /* transform: rotateY(180deg); */
  filter: grayscale(100%);
  animation: load .5s linear 0s infinite;
}
@keyframes load {
  100%{
    transform: rotate(360deg);
  }
}
@media (max-width: 620px) {
  .i-visible-blind{
    position: absolute;
    right: 2%;
    opacity: .7;
    top: 45% !important;
  }
  .investmentPageForm {
    width: 100%;
    /* margin-top: 3rem; */
  }
  .investmentPageForm .i-logo {
    margin: 20px 20px;
    margin-left: auto;
    width: 120px;
  }
  .investment {
    flex-direction: column;
  }
  .investment h1 {
    font-size: 22px;
    font-weight: 900;
  }
  .investment form {
    width: 100%;
  }
  .investment form label {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .investment form label input {
    width: 100%;
    border: 1px solid;
    border-radius: 5px;
    outline: none;
    padding: 5px;
  }
  .investment form label select {
    width: 100%;
  }
  .investment form small {
    font-size: 10px;
    margin-right: 0;
  }
  #dob {
    flex-direction: column;
    gap: 0;
    /* margin-block: 10px; */
    /* width: 100%; */
    padding: 0;
    align-items: flex-start;
    justify-content: center;
  }
  #dob p{
    width: 90%;
  }
  #dob span{
    margin-top: -2rem;
    width: 100%;
    border-right: none;
  }
  .datePicker{
    width: 100%;
    padding: 0;margin-top: 2rem;
  }
  .investment #investAmts {
    margin-top: -3rem;
    margin-left: 1rem;
  }
  .ndpr{
    margin-left: 0;
  }
  .investment .btns {
    width: 100%;
    font-size: 12px;
    padding: 5px;
  }
  .investment .btns button{
    width: 200px;
  }
  .investment section {
    width: 100%;
    margin-top: 2rem;
  }
  .investment section img {
    width: 200px;
    transform: translateX(10%);
  }
}
