.qualities{
    display: flex;
    width: 90%;
    margin: auto;
    margin-top: 3rem;
    margin-bottom: 5rem;
}
.qualities main:nth-child(1){
    width: 35%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.qualities main .goes{
    font-size: 35px;
    color: #082E88;
} 
.qualities main:nth-child(1) article{
    display: flex;
    flex-direction: column;
}
.qualities main:nth-child(1) article div{
    padding-block: 1rem;
}
.qualities main:nth-child(1) article div h1{
    font-size: 25px;
}
.qualities main:nth-child(2){
    width: 30%;

}
.qualities main:nth-child(2) img:nth-child(1){
    width: 200px;
    transform: translateX(4rem);
}
.qualities main:nth-child(3){
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.qualities main:nth-child(3) h1{
    font-size: 30px;
}


@media (max-width:620px){
    .qualities{
        width: 100%;
        flex-direction: column;
        gap: 4rem;
    }
    .qualities main:nth-child(1){
        width: 100%;
    }
    .qualities main .goes{
        font-size: 25px;
        margin-bottom: -1rem;
    }
    .qualities main:nth-child(1) article div h1{
        font-size: 20px;
    }
    .qualities main:nth-child(2){
       display: none;
    }
    .qualities main:nth-child(3){
        width: 100%;
        gap: 1rem;
        align-items: center;
    }
    .qualities main:nth-child(3) div{
        display: flex;
        gap: 1rem;
    }
    .qualities main:nth-child(3) div img{
        width: 150px;
    }
}