.patners{
    width: 90%;
    margin: auto;
    text-align: center;
    margin-block: 10rem;
}
.patners h1{
    font-size: 40px;
    margin-bottom: 3rem;
}
.patners main{
    display: flex;
    justify-content: space-between;
}
@media (max-width:620px){
    .patners h1{
        font-size: 25px
    }
    .patners{
        margin-block: 5rem;
    }
    .patners main{
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }
    .patners main img{
        width: 200px;
    }
}