.CO_form{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 90vh;
    width: 500px;
    border-radius: 5px;
    padding: 2rem;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0.1, 0.1);
    overflow: auto;
 
}
.CO_form section{
    padding: 10px;
}
 
.C-logo{
    margin-bottom: 20px;
}
  .C-wel{
 
    color: black;
    text-align: center;
    margin-bottom: 15px;
   
     
}
.CO_form .C-wel h1{
    font-size: 18px;
    margin-bottom: 3px;
}
.C_form{
    width: 100%;
}
.C_form form{
    display: flex;
    flex-direction: column;
    gap: .5rem;
     

}
.C_form form label{
    display: flex;
    flex-direction: column;
}
.C_form form label input{
    padding: 5px;
    border-radius: 5px;
    border: 1px solid gainsboro;
    outline: none;
}
.C_form form div:nth-child(2){
    display: flex;
    gap: 4%;
}
.C_form form div:nth-child(2) label{
    width: 48%;
}
.C_form button{
    width: 100%;
    padding-block: 7px;
    font-size: 16px;
    border: none;
    background-color: #082e88;
    color: white;
    border-radius: 3px;
    margin-top: 1rem;
    cursor: pointer;
}
.CO_form .WIL{
margin: auto;
text-align: center;
font-size: 12px;
color: #5475c0;
}
.CO_form .WIL img{
    width: 140px;
}
.CO_pay{
    width: 100%;
}
.CO_pay nav ul{
    display: flex;
    list-style: none;
    justify-content: space-between;
    border: 1px solid gainsboro;
    width: 100%;
}
.CO_pay nav ul li{
    width: 50%;
    text-align: center;
    padding: 5px 5px;
    font-weight: 600;
}
/* .CO_pay section{
    border: 1px solid;
} */

.CO_brkdwn{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: fit-content;
    /* margin-top: 3rem; */
    width: 100%;
    position: relative;
}
.CO_brkdwn .orderbrkdwn{
    border-bottom: 2px dotted #0051FF;
    /* padding: 10px; */
}

.CO_brkdwn .orderbrkdwn h2{
    text-align: center;
    font-size: 18px;
    margin: 0;
    margin-bottom: 20px;
    font-weight: 800;
    font-size: 16px;
}
.CO_brkdwn .orderbrkdwn ul{
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 14px;
    padding: 0pc 20px;
}
.CO_brkdwn .orderbrkdwn ul li{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: #0051FF;
    font-weight: 700;
}
.CO_brkdwn .orderbrkdwn ul li strong{
    color: black;
    width: 40%;
}
.CO_brkdwn .payAmt{
    padding: 0px 20px;
}
.CO_brkdwn .payAmt h2{
    font-size: 18px;
    color: #FF0000;
    font-weight: 700;
}
.CO_brkdwn .payAmt h1{
    font-size: 18px;
    font-weight: 800;
}
.CO_brkdwn .payAmt h1 span{
    color: #0051FF;
}
.CO_brkdwn .bckOver{
    text-align: center;
    padding: 10px;
    border-radius: 40px;
    background-color: white;
    transform: translateY(1rem);
    font-family: Agrandir;
    font-weight: 900;
    line-height: 26px;
    color: #0051FF;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.CO_brkdwn .payNow button{
    width: 100%;
    margin-top: 1rem;
    padding: 10px 20px;
    background-color: #082e88;
    color: white;
    border: none;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
}
.CO_brkdwn .acct_det{
    margin: auto;
    margin-top: 1rem;
    border-radius: 10px;
    width: 100%;
    padding-block: 1rem;
    background-color: hsl(0, 0%, 96%);
}
.CO_brkdwn .acct_det ul{
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.CO_brkdwn .acct_det ul li{
    display: flex;
    flex-direction: column;
    text-align: center;
}
.CO_brkdwn .acct_det ul li small{
    font-size: 12px;
}
.CO_brkdwn .acct_det ul li b{
    font-size: 20px;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
}
.CO_exp{
    margin-top: 2rem;
    font-weight: 500;
    font-size: 14px;
    opacity: .8;
    text-align: center;
}
.load{
    animation: load 3s linear infinite;
}
@keyframes load {
    100%{
        transform: rotate(360deg);
    }
}
.CO_paymentSUcess{
    display: flex;
    flex-direction: column;
   
    align-items: center;
    margin-block: 1rem;
}
.CO_paymentSUcess main{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}
.CO_paymentSUcess main h3{
    opacity: .7;
}
.payCard{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    gap: 2rem;
}
.payCard main{
    /* border: 1px solid; */
    border-radius: 10px;
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 10px;
    background-color: rgb(242, 240, 240);
    transition-duration: 1s;
}
.payCard main div:nth-child(1){
    display: flex;
    justify-content: space-between;
}
.payCard main div:nth-child(1) img:nth-child(2){
    /* border: 1px solid; */
    width: 120px;
    height: fit-content;
    margin-top: .5rem;
}
.payCard main div:nth-child(2) p{
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 600;
    margin-left: .5rem;
} 
.payCard main div:nth-child(3){
    display: flex;
    justify-content: space-between;
}
.payCard main div:nth-child(3) h4{
    margin-top: auto;
}
.payCard main div:nth-child(3) span{
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 600;
    align-items: center;
}
.payCard article{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.payCard article label{
    display: flex;
    flex-direction: column;
    font-weight: 700;
    color: #082e88;
    gap: .3rem;
}
.payCard article label input{
    border: 1px solid gainsboro;
    border-radius: 5px;
    padding: 10px 5px;
    outline: none;
    letter-spacing: 2px;
    font-size: 16px;

}
.payCard article div{
    display: flex;
    gap: 4%;
}
.payCard article div label{
    width: 48%;
}
.modal{
    border: 1px solid gainsboro;
    padding: 48px;
    margin: auto;
    border-radius: 10px;
    position: fixed;
    left: 0;
    right: 0;
    top: 5%;
    bottom: 10%;
    z-index: 2;
    background-color: white;
    width: 400px;
    height:max-content;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0.1, 0.1);
    opacity: 1 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition-duration: 1s;
}
.modal form label{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
}
.modal form label input{
    width: 50%;
    padding: 10px;
    font-size: 18px;
    border: 1px solid gainsboro;
    outline: none;
    text-align: center;
    border-radius: 10px;
    letter-spacing: 3px;

}

.modal-header-picture{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.modal-picture-circle{
    width: 95px;
    height: 95px;
    border-radius: 50%;
    border: 2px solid #082E88;
    display: flex;
    justify-content: center;
    align-items: center;
}


.imgPic{
    margin-left: 55px;
    margin-top: 25px;
}

.otpDiv{
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    width: 100%;
 
}

.otpForm{
    display: flex;
 flex-direction: column;
    align-items: center;
    width: 100%;
}

.otpInput{
    height: 41px;
    border-radius: 5px;
    outline: none;
    text-align: center;
    font-size: 14px;
    border: 1px solid gray;
    
}
.otpText{
    color: #172C63;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-top: 30px;
 
}
.otpVerifyButton{
    height: 41px;
    border-radius: 5px;
    outline: none;
    text-align: center;
    font-size: 14px;
    border: 1px solid #0C6904;
    background: #5FCE2F;
    color: #fff;
    min-width: 100px;
    max-width: max-content;
    
}

.otpCancelPayment{
    border-radius: 15px;
    border: 0px solid #A4A642;
    background: #D9D9D9;
    width: max-content;
    height: 41px;
    margin-top: 20px;
    display: flex;
    justify-content:  flex-start;
    gap: 8px;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
    
}


.otpSuccess{
    border-radius: 15px;
    border: 0px solid #0C6904;
    background: #5FCE2F;
    width: max-content;
    height: 41px;
    margin-top: 40px;
    display: flex;
    justify-content:  flex-start;
    gap: 8px;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
    color: #fff;
    
}
.imgSecure{
    margin-top: 20px;
}
.formInput{
    height: 40px;
    margin-top: 6px;
}

input[type=number] {
    -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.orderBreakdown{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    gap:12px
}

.eachRow{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  
}

.rowName{
    width: 50%;
    color: #0051FF;
    font-weight: 700;
    text-wrap: wrap;
    font-size: 14px;

}
.rowContent{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    color: black;
    text-wrap: wrap;
    font-size: 14px;
    font-weight: 700;

}



.account-details-container{
    /* width: 500px; */
    height: 178px;
    border-radius: 15px;
    opacity: 0.4;
    background: rgba(169, 170, 236, 0.92);
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    gap: 15px;
    
}

.title-details-box{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


.account-title{
    color:#0149f2;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.account-details{
    color: #0145f2;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.cardText{
    font-size: 12px;
}
.accountDetailBox{
    display: flex;
 flex-direction: column;
 text-align: center;
    width: 100%;
 margin-bottom: 10px;
}

.rowName2{
   
    color: #0051FF;
    font-weight: 700;
    text-wrap: wrap;
    font-size: 14px;

}
 
 
.rowContent2{
    color: black;
    text-wrap: wrap;
    font-size: 14px;
    font-weight: 700;

}


@media screen and (max-width:768px) {
    .CO_form{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 95vw;
        height: 100vh;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0.1, 0.1);  
        padding:1rem;     
        margin: 2rem;
    }

    .CO_brkdwn .orderbrkdwn h2{
       
        font-weight: 700;
        font-size: 14px;
    }
    .rowName{
        font-weight: 700;
        font-size: 13px;
    }
    .rowContent{
        font-weight: 700;
        font-size: 11px;
    }
    
}