.facilitate{
    display: flex;
    flex-direction: column;
    padding-top: 8rem;
    padding-bottom: 8rem;
    margin-top: 10rem;
    margin-bottom: 2rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.facilitate article{
    text-align: center;
    margin-bottom: 4rem;
}
.facilitate article h1{
    font-size: 35px;
    line-height: 50px;
    margin-bottom: 10px;
    color: white;
}
.facilitate article p{
    font-size: 20px;
    color: black;
    margin-bottom: 4rem;
}
.facilitate-seamless{
    display: flex;
    gap: 1rem;
    border-radius: 30px;
    width: 80%;
    margin: auto;
}
.facilitate-seamless main{
    width: 50%;
}
.facilitate-seamless main:nth-child(1){
    display: flex;
    align-items: center;
    padding-left: 6rem;
}
.facilitate-seamless main:nth-child(1) div{
    width: 40%;
    padding: 32px;
    color: white;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}
.facilitate-seamless main:nth-child(1) div img{
    width: 50px;
}
.facilitate-seamless main:nth-child(2){
    display: flex;
    gap: 2rem;
    align-items: center;
}
.facilitate-seamless main .logo-con{
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    height: 60%;
    position: relative;
    display: flex;
}
.facilitate-seamless main .logo-con span{
    margin: auto;

    /* border: 1px solid; */
}
.facilitate-seamless main .port-wallet{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.facilitate-seamless main .port-wallet img{
    width: 300px;
}
.facilitate .inclusion{
    display: flex;
    gap: 1rem;
    width: 80%;
    margin: auto;
    margin-top: 5rem;
    background-color: #051D57;
    padding: 80px 32px;
    border-radius: 30px;
}
.facilitate .inclusion main{
    width: 50%;
}
.facilitate .inclusion main:nth-child(1){
    display: flex;
    align-items: center;
}
.facilitate .inclusion main:nth-child(1) div{
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 16px 80px;
    font-size: 25px;
    color: white;
}
.facilitate .inclusion main:nth-child(2) img{
    width: 500px;
}

@media (max-width:620px){
    .facilitate{
        margin-top: 0rem;
        padding: 4rem 1rem;
        margin: 4rem -2rem;
        
    background-size: cover;
    background-position: none;
    background-repeat: no-repeat;
    }
    .facilitate article h1{
        font-size: 25px;
        line-height: 32px;
    }
    .facilitate article p{
        font-size: 16px;
        margin-bottom: 1rem;
    }
    .facilitate-seamless{
        width: 90%;
        gap: 3rem;
        /* flex-direction: column; */
    }
    .facilitate-seamless main:nth-child(1){
        padding-left: 0rem;
        justify-content: center;
    }
    .facilitate-seamless main:nth-child(1) div{
        flex-direction: row;
        gap: 1rem;
        align-items: center;
        width: 100%;
        font-size: 10px;
    }
    .facilitate-seamless main .logo-con{
        display: none;
    }
    .facilitate-seamless main .port-wallet img{
        width: 120px;
    }
    .facilitate .inclusion{
        flex-direction: column;
        padding: 10px;
    }
    .facilitate .inclusion main:nth-child(1) div{
        padding: 0;
        font-size: 14px;
        flex-direction: row;
    }
    .facilitate .inclusion main:nth-child(2) img{
        width: 300px;
    }
}